import React, { useState } from 'react';
import questionObject from "../../data/questionObject";
import './App.scss';
import Header from '../Header/Header';
import QuizQuestions from '../QuizQuestions/QuizQuestions';
import Summary from '../Summary/Summary';
import Footer from '../Footer/Footer';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-58GQ6HZ',
  dataLayerName: 'dataLayer'
}

TagManager.initialize(tagManagerArgs)

const App = () => {

  const createBaseArray = questionObject.map((item, index) => { return 'empty' });
  const [answers, setAnswers] = useState({ answers: [...createBaseArray] });
  const [finishedQuiz, setFinishedQuiz] = useState(false);

  return (
    <div className="App">
      <div className="mainContainer">
        <Header></Header>
        <QuizQuestions setAnswers={setAnswers} answers={answers} visibility={finishedQuiz} setFinished={setFinishedQuiz}></QuizQuestions>
        {finishedQuiz && <Summary answers={answers.answers}></Summary>}
      </div>
      <Footer></Footer>
    </div>
  );
}


export default App;
