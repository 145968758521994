import React from "react";
import magazineImage from "../../assets/magazineLater.jpg";
import './MagazineContainer.scss';

const MagazineContainer = () => {

  return (
    <div className="magazine">
      <img alt="Magazine Later" src={magazineImage}></img>
    </div>
  )
}

export default MagazineContainer;
