import React from "react";
import "./Suggestions.scss";
import MagazineContainer from "../MagazineContainer/MagazineContainer";

const copy = {
	titelCopy: `Lees inspirerende verhalen`,
	mainCopy: `Je keuzes voor een uitvaart zeggen veel over hoe je in het leven staat. We geven je graag het tijdschrift LATER cadeau. Om inspiratie te halen uit de vele verhalen over later.`,
	ctaCopy: `Laat je inspireren`
};

const Suggestions = (props) => {
	const visibility = props.visibility;
	const callbackHide = props.callbackHide;

	const shuffleAndClose = () => {
		callbackHide();
	}

	return (
		visibility &&
		<>
			<div className="Suggestions">
				<div className="darkOverlay"></div>
				<div className="infoContainer">
					<div className="contentContainer">
						<h1>{copy.titelCopy}</h1>
						<p>{copy.mainCopy}</p>
						<button onClick={() => window.open('https://www.dela.nl/inspiratie/infoverzoek/an1_1_11-aanvragen-magazine-later')} className="showPopupButton">{copy.ctaCopy}</button>
					</div>
					<div className="magazineContainer">
						<MagazineContainer />
					</div>
				</div>
				<div className="suggestionFooter">
					<div onClick={shuffleAndClose} className="hideSuggestionsButton">Terug naar overzicht</div>
				</div>
			</div>
		</>
	)
}

export default Suggestions;
