import "./Footer.scss";
import React from 'react';

const Footer = () => {
	return (
		<div className="footer" alt="Footer">
			<div className="row">
				<div className="col-sm-4">
					<h2><a href="https://dela.nl/service-en-contact">Service en contact</a></h2>
					<p><a href="https://dela.nl/service-en-contact">Contact met ons opnemen</a></p>
					<p><a href="https://dela.nl/login">Wijzigingen doorgeven</a></p>
					<p><a href="https://dela.nl/service-en-contact/contact-met-ons-opnemen/klacht-of-compliment-indienen">Klacht of compliment doorgeven </a></p>
					<p><a href="https://dela.nl/over-dela/over-cooperatie-dela/onze-gegevens">Handelsregister en vergunningen</a></p>
					<h2><a href="https://dela.nl/voor-leden">Ledenservice</a></h2>
					<p><a href="https://dela.nl/voor-leden/onze-service">DELA VoorelkaarDesk</a></p>
					<p><a href="https://dela.nl/voor-leden/dela-notarisservice">DELA NotarisService</a></p>
					<p><a rel="noopener noreferrer" href="https://dela.foleon.com/kroniek-najaar-2021/kroniek-najaar-2021/kroniek-homepage/" target="_blank">Ledenmagazine Kroniek</a></p>
					<h2><a href="https://dela.nl/voor-leden/situaties">Persoonlijke situaties</a></h2>
					<p><a href="https://dela.nl/voor-leden/verhuizen">Verhuizen</a></p>
					<p><a href="https://dela.nl/voor-leden/kinderen">Gezin met kinderen</a></p>
					<p><a href="https://dela.nl/voor-leden/ouder-worden">Ouder worden</a></p>
					<p><a href="https://dela.nl/voor-leden/samenwonen">Samenwonen</a></p>
					<p><a href="https://dela.nl/voor-leden/uit-elkaar">Uit elkaar gaan</a></p>
					<p><a href="https://dela.nl/voor-leden/nadenken-uitvaart">Nadenken over de uitvaart</a></p>
				</div>
				<div className="col-sm-4 padding">
					<h2><a href="https://dela.nl/verzekeringen/dienstverleningsdocument">De dienstverlening van DELA</a></h2>
					<p><a href="https://dela.nl/verzekeringen/dienstverleningsdocument">Wat houdt onze dienstverlening in en wat zijn de kosten?</a></p>
					<p><a href="https://dela.nl/intermediair">DELA Intermediair</a></p>
					<p><a href="https://dela.nl/bewindvoerders">DELA BewindvoerdersService</a></p>
					<h2><a href="https://dela.nl/uitvaart">Alles over de uitvaart</a></h2>
					<p><a href="https://dela.nl/uitvaart/regelen">Een uitvaart regelen bij DELA</a></p>
					<p><a href="https://dela.nl/uitvaart/regelen/uitvaart-zonder-verzekering">Een uitvaart regelen zonder verzekering</a></p>
					<p><a href="https://dela.nl/herinneringsproducten">Webshop herinneringsproducten</a></p>
					<h2><a href="https://dela.nl/inspiratie">Inspiratie</a></h2>
					<p><a href="https://dela.nl/inspiratie/nu-en-later">Zorgen voor nu en later</a></p>
					<p><a href="https://dela.nl/inspiratie/uitvaart">Rondom de uitvaart</a></p>
					<p><a href="https://dela.nl/inspiratie/rouwen-en-doorleven">Rouwen en doorleven</a></p>
				</div>
				<div className="col-sm-4">
					<h2><a href="https://dela.nl/over-dela">Over DELA</a></h2>
					<p><a href="https://dela.nl/over-dela/nieuws-en-media">Nieuws</a></p>
					<p><a href="https://dela.nl/over-dela/evenementen">Evenementen</a></p>
					<p><a href="https://dela.nl/werken-bij-dela">Werken bij DELA</a></p>
					<p><a href="https://dela.nl/werken-bij-dela/vacatures">Vacatures</a></p>
					<p><a href="https://dela.nl/over-dela/innovatie">Innovatie bij DELA</a></p>
					<p><a href="https://dela.nl/over-dela/dela-fonds">DELA Fonds</a></p>
					<p><a href="https://dela.nl/over-dela/over-cooperatie-dela/governance">Bestuur, toezicht en verantwoording</a></p>
					<p><a href="https://dela.nl/over-dela/over-cooperatie-dela/governance/fraudebeleid">Fraudebeleid</a></p>
					<p><a href="https://dela.nl/verzekeringen/keurmerken">Klanttevredenheidsonderzoek</a></p>
					<p><a rel="noopener noreferrer" href="http://www.dela.be" target="_blank">DELA in België</a></p>
					<h2><a href="https://www.dela.nl/mijndela">Mijn DELA</a></h2>
					<p><a href="https://dela.nl/mijn-dela-gebruiksvoorwaarden">Gebruiksvoorwaarden Mijn DELA</a></p>
				</div>
				<div className="ft-bar-topline"></div>
			</div>
		</div>
	);
};

export default Footer;
