import getBackgroundImage from "./getBackgroundImage";

const preloadImages = (questionObject, callbackFunction) => {
	let preloadCount = 0;
	const imageArray = [];

	// Push all images to new array
	for (let value of Object.values(questionObject)) {
		imageArray.push(getBackgroundImage(value[1], 'absolutePath'));
		imageArray.push(getBackgroundImage(value[2], 'absolutePath'));
	}

	// Load all images and keep count
	imageArray.forEach((value, index) => {
		const img = new Image();
			img.onload = () => {
			preloadCount = preloadCount + 1;

			// If preload complete, fire callback
			if (preloadCount === imageArray.length) {
				callbackFunction();
			}
		}
		img.src = value;
	})
}

export default preloadImages;
