import "../../components/variables.scss";
import "../ContactMenu/ContactMenu.scss";
import React from "react";

const ContactMenu = ({ contactOpen }) => {
  return (
    <div className={`contactMenu ${contactOpen ? 'open' : 'closed'}`}>
      <section className='heading'><h1>Contact</h1></section>
      <section className='contactDetails'>
        <a href="tel:0850517126" className="phoneIcon">
          <span className="textSmall">Overlijden melden</span>
          <span className="textLarge">085 051 7126</span>
        </a>
        <br />
        <a href="https://www.dela.nl/service-en-contact" className="contactIcon">
          <span className="textSmall">Stel een vraag</span>
          <span className="textLarge">Service en contact</span>
          <span>Wij helpen u graag</span>
        </a>
      </section>
    </div>
  );
}

export default ContactMenu;
