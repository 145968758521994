import "./Question.scss";
import getBackgroundImage from "../../helpers/getBackgroundImage";
import React, { useState, useEffect } from "react";

const Question = ({ index, value, activeQuestion, registerAnswer }) => {

  const [animateLeft, setAnimationLeft] = useState('default');
  const [animateRight, setAnimationRight] = useState('default');
  const [visibiltyQuestion, setVisibilityQuestion] = useState('visible');
  const [answer, setNewAnswer] = useState(0);

  const updateAnswer = (e) => {
    e.preventDefault();
    const questionId = parseInt(e.target.dataset.questionId);
    const answerId = parseInt(e.target.dataset.answerId);
    setNewAnswer(answerId);
    setTimeout(() => {
      setVisibilityQuestion('invisible');
    }, 200)
    setTimeout(() => {
      registerAnswer(questionId, answerId);
    }, 500)
  };

  useEffect(() => {
    // Animate class
    if (answer === 1) {
      setAnimationLeft('clickedAnswer');
      setAnimationRight('notClickedAnswer');
    } else if (answer === 2) {
      setAnimationLeft('notClickedAnswer');
      setAnimationRight('clickedAnswer');
    }
  }, [answer]);

  // Rendered JSX Components
  return (<div key={index} className={`questionContainer ${visibiltyQuestion}`} style={{ display: activeQuestion.activeQuestion === index ? 'flex' : 'none' }}>
    <div className="questionCopy">Wat kies jij voor een uitvaart?</div>
    <div className={`totalAnswers answerOne ${animateLeft}`} style={{ backgroundImage: getBackgroundImage(value[1]) }} data-question-id={index} data-answer-id={1} onClick={updateAnswer}>
      <div className="answerButton">{value[1].answer}</div>
    </div>
    <div className={`totalAnswers answerTwo ${animateRight}`} style={{ backgroundImage: getBackgroundImage(value[2]) }} data-question-id={index} data-answer-id={2} onClick={updateAnswer}>
      <div className="answerButton">{value[2].answer}</div>
    </div>
  </div>);
};

export default Question;
