import "./Bullet.scss";
import React from "react";

const Bullet = ({ index, activeQuestion, switchQuestion, answerIsEmpty }) => {

  const clickBullet = (e) => {
    const clickedBullet = parseInt(e.target.dataset.questionId);
    const bulletState = e.target.dataset.bulletState;
    if (bulletState !== 'notClickable') switchQuestion(clickedBullet);
  }

  const bulletState = answerIsEmpty && activeQuestion !== index ? 'notClickable' : activeQuestion === index ? 'active' : '';

  return (
    <div className={`bullet ${bulletState}`} data-question-id={index} data-bullet-state={bulletState} onClick={clickBullet}> {index + 1}</div >
  )
}

export default Bullet;
