const questionObject = [
	{
		questionId: 0,
		1: {
			answer: "Bitterballen na de uitvaartdienst",
			imageDesktop: "bitterbal_desktop.jpg",
			imageMobile: "bitterbal_mobile.jpg"
		},
		2: {
			answer: "Ik ga 100% vegan",
			imageDesktop: "vegan_desktop.jpg",
			imageMobile: "vegan_mobile.jpg"
		}
	}, {
		questionId: 1,
		1: {
			answer: "Een zee van bloemen",
			imageDesktop: "bloemenzee_desktop.jpg",
			imageMobile: "bloemenzee_mobile.jpg"
		},
		2: {
			answer: "Wekelijks boeket voor je geliefde",
			imageDesktop: "wekelijksboeket_desktop.jpg",
			imageMobile: "wekelijksboeket_mobile.jpg"
		}
	}, {
		questionId: 2,
		1: {
			answer: "Prachtige toespraak bij je uitvaart",
			imageDesktop: "prachtigetoespraken_desktop.jpg",
			imageMobile: "prachtigetoespraken_mobile.jpg"
		},
		2: {
			answer: "Vertel het me nú",
			imageDesktop: "vertelmenu_desktop.jpg",
			imageMobile: "vertelmenu_mobile.jpg"
		}
	}, {
		questionId: 3,
		1: {
			answer: "Op de boot door de gracht",
			imageDesktop: "bootgracht_desktop.jpg",
			imageMobile: "bootgracht_desktop.jpg"
		},
		2: {
			answer: "Klassieke camper",
			imageDesktop: "oldtimer_desktop.jpg",
			imageMobile: "oldtimer_mobile.jpg"
		}
	}, {
		questionId: 4,
		1: {
			answer: "Ik hoop dat ik alles wel heb gezegd",
			imageDesktop: "allesgezegd_desktop.jpg",
			imageMobile: "allesgezegd_mobile.jpg"
		},
		2: {
			answer: "Een persoonlijk afscheidsfilmpje",
			imageDesktop: "afscheidsfilmpje_desktop.jpg",
			imageMobile: "afscheidsfilmpje_mobile.jpg"
		}
	}, {
		questionId: 5,
		1: {
			answer: "Een vol stadion",
			imageDesktop: "stadion_desktop.jpg",
			imageMobile: "stadion_mobile.jpg"
		},
		2: {
			answer: "Alleen mijn beste vrienden",
			imageDesktop: "bestevrienden_desktop.jpg",
			imageMobile: "bestevrienden_mobile.jpg"
		}
	}, {
		questionId: 6,
		1: {
			answer: "Afscheid met gevoelige liedjes",
			imageDesktop: "liedjes_desktop.jpg",
			imageMobile: "liedjes_mobile.jpg"
		},
		2: {
			answer: "Briljante roast",
			imageDesktop: "roast_desktop.jpg",
			imageMobile: "roast_mobile.jpg"
		}
	}, {
		questionId: 7,
		1: {
			answer: "Vuurwerk en fakkels",
			imageDesktop: "vuurwerk_desktop.jpg",
			imageMobile: "vuurwerk_mobile.jpg"
		},
		2: {
			answer: "Moment van stilte",
			imageDesktop: "minuutstilte_desktop.jpg",
			imageMobile: "minuutstilte_mobile.jpg"
		}
	}];

export default questionObject;
