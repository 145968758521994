import "./QuizQuestions.scss";
import questionObject from "../../data/questionObject";
import preloadImages from "../../helpers/preloadImages";
import React, { useState, useEffect } from "react";
import Question from '../Question/Question';
import Bullet from '../Bullet/Bullet';

const QuizQuestions = (props) => {
	// Variables  + state
	const setFinished = props.setFinished;
	const visibility = props.visibility;
	const setAnswers = props.setAnswers;
	const answers = props.answers;
	const [activeQuestion, setActiveQuestion] = useState({ activeQuestion: 0 });
	const [imagesPreloaded, setImagesPreloaded] = useState(false);

	// UseEffect Hooks to control state logic
	useEffect(() => {
		preloadImages(questionObject, () => setImagesPreloaded(true));
	}, [])

	// Helper functions
	const switchQuestion = (questionId) => {
		if (questionId > questionObject.length) return;
		setActiveQuestion({ activeQuestion: questionId });
	};

	// Update state
	const registerAnswer = (questionId, answerId) => {
		setAnswers(({ answers }) => {
			let updatedAnswer = [...answers];
			updatedAnswer[questionId] = questionObject[questionId][answerId];
			updatedAnswer[questionId].answerId = answerId;

			//check if question is filled in or not
			let emptyQuestions = updatedAnswer.indexOf('empty') > -1 ? true : false;

			if (emptyQuestions) {
				//check what questions are still empty. Get the first one on the list to go to.
				//otherwise, go to the next question

				updatedAnswer.every((answer, index) => {
					if (answer === 'empty') {
						switchQuestion(index);
						return false;
					} else {
						return true;
					}
				});
			} else {
				setFinished(true);
			}
			return { answers: updatedAnswer };
		});
	}

	// Component functions
	const Questions = ({ questionObject }) => questionObject.map((value, index) => {
		return (<Question key={index} index={index} activeQuestion={activeQuestion} value={value} registerAnswer={registerAnswer} maxQuestions={questionObject.length}></Question>)
	})

	const Bullets = ({ questionObject }) => questionObject.map((value, index, array) => {
		const answerIsEmpty = answers.answers[index] === 'empty' ? true : false;
		return <Bullet key={index} index={index} activeQuestion={activeQuestion.activeQuestion} switchQuestion={switchQuestion} answers={setAnswers} answerIsEmpty={answerIsEmpty} />
	});

	// Rendered JSX Components
	return (imagesPreloaded ?
		<>
			<div className="quizContainer" style={{ display: visibility ? 'none' : 'block' }}>
				<Questions questionObject={questionObject}></Questions>
				<div className="bulletContainer">
					<Bullets questionObject={questionObject}></Bullets>
				</div>
			</div>
		</>
		// Make a nice preloader
		: <div className="preloader">Application is loading</div>
	)
};

export default QuizQuestions;
