import "./Summary.scss";
import Suggestions from '../Suggestions/Suggestions';
import getBackgroundImage from "../../helpers/getBackgroundImage";
import React, { useState, useEffect } from "react";

const Summary = (props) => {
	// Variables  + state
	const answers = props.answers;

	const [showSuggestions, setShowSuggestions] = useState(false);

	// Trigger visibility after x seconds, just once
	useEffect(() => {
			setTimeout(() => setShowSuggestions(true), 3000)
	}, []);

	// Component functions
	const Answers = ({ answers }) => answers.map((answer, index) => {
		return (
			<div key={index} className="answerContainer" style={{ backgroundImage: getBackgroundImage(answer) }}>
				<div className={`answerCopy ${answer.answerId === 1 ? 'purple' : 'orange'}`}>
					{`${index + 1}. ${answer.answer}`}
				</div>
			</div >
		)
	});

	// Rendered JSX Components
	return (
		<>
			<div className="SummaryContainer">
				<div className="Summary">
					<div className="overviewAnswers">
						<Answers answers={answers}></Answers>
						<div className="showPopup tablet">
							<p className="storyCopy">Ontdek welk verhaal wat bij jou past</p>
							<button onClick={() => setShowSuggestions(!showSuggestions)} className="showPopupButton tablet">
								Bekijk hier jouw verhaal
							</button>
						</div>
					</div>
					<div className="showPopup mobile">
						<p className="storyCopy">Ontdek welk verhaal wat bij jou past</p>
						<button onClick={() => setShowSuggestions(!showSuggestions)} className="showPopupButton mobile">
							Laat je inspireren
						</button>
					</div>
					<div className="questionCopy">Dit past bij jou</div>
				</div>
				<Suggestions visibility={showSuggestions} callbackHide={() => setShowSuggestions(!showSuggestions)} />
			</div>
		</>
	);
}

export default Summary
