import "./Header.scss";
import logo from '../../assets/logo.svg'
import phoneIcon from '../../assets/phoneIcon.svg';
import closeIcon from '../../assets/closeIcon.svg';
import ContactMenu from '../ContactMenu/ContactMenu.js';
import React, { useState } from "react";

const Header = () => {

  const [contactOpen, setContactOpen] = useState(false);

  const openContact = () => {
    setContactOpen(!contactOpen);
  }

  return (
    <>
      <div className="header">
        <img className="logo" src={logo} alt="Dela Logo" onClick={() => window.open('https://www.dela.nl')} />
        <div className="content">
          <div className="overlijdenMelden">Meer&nbsp;informatie <a href="tel:+31850480692" className="header__contact calltracker telephoneNumber" data-aen="AEN1435-1" data-acalltrackerelementnumber="AEN1435-1" data-acalltrackerelementid="0" data-acalltrackerregion="0">085&nbsp;051&nbsp;7126</a></div>
        </div>
        <div className="contact">
          <div
            className={`contactButton ${contactOpen ? 'open' : 'closed'}`}
            onClick={openContact}
          >
                <img className="phoneIcon" src={phoneIcon} alt="Open Contact" />
                <img className="closeIcon" src={closeIcon} alt="Close Contact" />
          </div>
        </div>
      </div>
      <ContactMenu contactOpen={contactOpen}></ContactMenu>
    </>
  )
}

export default Header;
